<template>
  <div class="account-view">
    <div class="account-view__wrapper">
      <Section limiter>
        <BreadCrumbs :params="{navigation, color: 'rgba(0, 0, 0, .5)'}"/>
      </Section>
      <Section limiter>
        <Row class="grid-sm-full"
             justify="between">
          <AccountMenu v-if="$laptop"
                       class="grid-md-4 grid-lg-4"/>
          <Column class="grid-sm-full">
            <AccountContent class="grid-sm-full grid-md-8 grid-lg-8">
              <AccountDashboard/>
            </AccountContent>
            <div class="account-view__become-guide">
              <FormationPortal
                  title="Стать гидом"
                  description="На нашем портале вы можете разместить свои услуги в качестве гида"
                  backgroundPosition="center right">
                <Button v-if="$my && $my.guide && $my.guide.status === 'new'"
                        color="transparent"
                        v-on:click.native="$openPopupAddGuide($my.guide)">Стать гидом
                </Button>
                <Button v-if="$my && $my.guide && $my.guide.status === 'moderated'"
                        color="transparent"
                        disabled>Заявка на модерации
                </Button>
                <Button v-if="$my && $my.guide && $my.guide.status === 'active'"
                        color="transparent"
                        v-on:click.native="$openPopupAddGuide($my.guide)">Просмотреть профиль
                </Button>
                <Button v-if="$my && $my.guide && $my.guide.status === 'nonactive'"
                        color="red"
                        v-on:click.native="$openPopupAddGuide($my.guide)">Заявка отклонена
                </Button>
              </FormationPortal>
            </div>
          </Column>
        </Row>
      </Section>
<ModifiedBillboard/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AccountView',
  data() {
    return {
      ready: false,
      guides: [],
      navigation: [{
        href: '/',
        title: 'Главная',
      }, {
        href: '/account',
        title: 'Личный кабинет',
      }],
    };
  },
  created() {
    this.$http.get('/api/guides?user=current')
    // eslint-disable-next-line no-return-assign
      .then((resolve) => {
        this.ready = true;
        this.guides = [...resolve.data.data];
        this.$store.commit('SET_MY_GUIDE', resolve.data.data[0]);
      });
  },
  watch: {
    $user() {
      this.$redirectIfGuest();
    },
  },
};
</script>
